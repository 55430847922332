import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    orderItem: any;
    recommendationData: any;
    tracking: boolean;
    activeStep: number
    orderDetails: any;
    orderId: any;
    subCategoryID: any;
    catalougeID: any;
    wishListItem: any;
    message: string;
    notifyModel: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BuyerOrderDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getOrderDetailsApiCallId: string = '';
    getRecommendationDataApiCallId: string = '';
    removeItemFromWatchlistApiCallId: string = '';
    cancelOrderApiCallId: string = '';
    addItemInWatchlistApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];

        this.state = {
            loading: false,
            orderItem: [],
            recommendationData: [],
            tracking: false,
            activeStep: 1,
            orderDetails: [],
            orderId: '',
            subCategoryID: '',
            catalougeID: '',
            wishListItem: [],
            message: '',
            notifyModel: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getOrderDetailsApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    this.setState({
                        orderDetails: responseJson.data?.attributes,
                        subCategoryID: responseJson.data?.attributes.catalogue?.attributes.sub_category_id,
                        catalougeID: responseJson.data?.attributes.catalogue?.id,
                    }, () => {
                        this.getRecommendationData();
                    });
                }
            } else if (apiRequestCallId === this.getRecommendationDataApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    this.setState({
                        recommendationData: responseJson.data,
                        wishListItem: responseJson.wishlist_items.product_ids
                    });
                }
            } else if (apiRequestCallId === this.addItemInWatchlistApiCallId) {
                this.setState({ loading: false });
                if (responseJson.message) {
                    this.getRecommendationData();
                    this.setState({
                        message: responseJson.message,
                        notifyModel: true
                    });
                } else if (responseJson.errors) {
                    this.setState({
                        message: responseJson.errors,
                        notifyModel: true
                    })
                }
            } else if (apiRequestCallId === this.removeItemFromWatchlistApiCallId) {
                this.setState({ loading: false });
                if (responseJson.message) {
                    this.getRecommendationData();
                    this.setState({
                        message: responseJson.message,
                        notifyModel: true
                    });
                } else if (responseJson.errors) {
                    this.setState({
                        message: responseJson.errors[0],
                        notifyModel: true
                    })
                }
            }

            else if (apiRequestCallId === this.cancelOrderApiCallId) {
                this.setState({ loading: false });
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const queryParameters = new URLSearchParams(window.location.search)
        const searchName = queryParameters.get("item_id")
        if (searchName !== null) {
            this.setState({
                orderId: searchName
            }, () => {
                this.getOrderDetails();
            })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    navigateToSeeAll = () => {
        this.props.navigation.navigate("BuyerMarketplace");
    }

    goBackOnOrderList = () => {
        this.props.navigation.navigate("CustomisedOrderStatus");
    }

    handleTracking = () => {
        this.setState({
            tracking: true
        })
    }

    handleWatchlistItem = (event: any, id: any, items: any, type: any) => {
        event.stopPropagation();
        this.checkWatchlistStatus(items, parseInt(id)) ?
            this.removeWatchlist(items, parseInt(id)) :
            this.addItemInWatchlist(type, id)
    }

    checkWatchlistStatus = (items: any, id: any) => {
        const index = items?.findIndex((data: any) => data.product_id === id);
        if (index !== -1) {
            return true;
        } else {
            return false;
        }
    }

    removeWatchlist = (items: any, id: any) => {
        const index = items.find((data: any) => data.product_id === id).favourite_id;
        if (index) {
            this.removeItemFromWatchlist(index)
        } else {
            return false;
        }
    }

    closeNotifyModel = () => {
        this.setState({
            notifyModel: false
        })
    }

    getOrderDetails = async () => {
        const header = { "Content-Type": "application/json", token: await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getOrderDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_order_management/order_items/${this.state.orderId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getRecommendationData = async () => {
        this.setState({ loading: true });
        const { subCategoryID, catalougeID } = this.state;
        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getRecommendationDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/related_catalogue?sub_category_id=${subCategoryID}&catalogue_id=${catalougeID}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addItemInWatchlist = async (type: any, id: any) => {
        this.setState({ loading: true });

        const httpBody = {
            "data": {
                "favouriteable_id": id,
                "type": type === 'catalogue' ? 'product' : type
            }
        }

        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addItemInWatchlistApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_favourite/favourites');
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeItemFromWatchlist = async (id: any) => {
        const header = { "Content-Type": "application/json", "token": await getStorageData("buerLoginToken") };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.removeItemFromWatchlistApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'bx_block_favourite/favourites/' + id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    cancelOrderApi = async () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: await getStorageData("buerLoginToken") };
        const httpBody = {
            "order_id": this.state.orderDetails.porter_order_id
        }
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.cancelOrderApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_order_management/orders/cancel_porter_order`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}