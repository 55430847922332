import { getStorageData } from "framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";

// Customizable Area Start
import SwiperCore, { Navigation } from 'swiper';
const navigation = require('react-navigation');
const postBaseUrl = require("../../../framework/src/config.js").baseURL;
interface ApiPayload{
    method: string;
    contentType?: string;
    body?: object | string;
    endPoint: string;
    type?: string;
}
interface ImgVideoPayload {
    filename: string;
    id: number;
    type: string;
    url: string;
}

interface CreatorDataPayload {
    full_name: string;
    profile_url: string | null;
    id: number;
}

interface PostAttributesPayload {
    id: number;
    description: string | null;
    name: string | null;
    location: string | null;
    body: string;
    active_message: boolean;
    account_id: number;
    created_at: string;
    updated_at: string;
    creator_details: CreatorDataPayload;
    images_and_videos: ImgVideoPayload[];
    comment_count: number;
    is_following_seller: boolean;
    like_count: number;
    is_liked: boolean;
    last_liked_by: null | string;
}

export interface PostDataPayloadType {
    id: string;
    attributes: PostAttributesPayload;
    type: string;
}
interface PostApiResponsePayload {
    data: PostDataPayloadType[]
}

interface CommentDetails {
    profile_photo: string;
    comment: string;
    sub_post_comments: CommentDetails[];
    like_count: number;
    replies_count: number;
    self_like: boolean;
    created_at: string;
    full_name: string | null;
    sub_comment_id: string;
  }
  interface Comment {
      attributes: CommentAttributes;
      type: string;
      id: string;
  }

  interface Meta {
    message: string;
  }
  interface CommentAttributes {
    details: CommentDetails;
  }

interface CommentsResponse {
    data: Comment[];
    meta: Meta;
  }


  interface LikeDataAttributes {
    likeable_id: number;
    likeable_type: string;
    like_by_id: number;
    created_at: string;
    updated_at: string;
  }
  
  interface LikeData {
    id: string;
    type: string;
    attributes: LikeDataAttributes;
  }
  
  interface LikeResponse {
    data: LikeData;
    message: string;
  } 

  interface SubPostCommentAttributes {
    id: number;
    account_id: number;
    comment_text: string;
    comment: Comment;
    created_at: string;
    updated_at: string;
  }
  
  interface SubPostCommentData {
    id: string;
    type: string;
    attributes: SubPostCommentAttributes;
  }
  
  interface SubPostCommentResponse {
    data: SubPostCommentData;
  }

export interface StoryUploaders {
  id: string;
  type: string;
  attributes: StoryAttributes;
}

interface StoryAttributes {
  id: number;
  title: string;
  description: string;
  duration: number;
  seen: boolean;
  location: string | null;
  color: string | null;
  activated: boolean;
  created_at: string;
  updated_at: string;
  creator_details: CreatorDetails;
  media: StoryMedia;
  duration_with_seconds: string;
  liked: boolean;
}

interface CreatorDetails {
  id: number;
  full_name: string;
  profile_url: string | null;
}

interface StoryMedia {
  id: number;
  filename: string;
  url: string;
  type: string;
}

interface AllStoryResponse {
  data: Array<StoryUploaders>
}

SwiperCore.use([Navigation]);
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: typeof navigation;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isAppLoader: boolean;
    sellerPostData: PostDataPayloadType[];
    buyerPostLoader: boolean;
    feedPageNo: number;
    isFeedLoader: boolean;
    islatestPost: boolean;
    hasMorePosts: boolean;
    isSideBar: boolean;
    showCommentBox: string;
    clickReply: number[];
    setPostCommentId: string;
    listCommentData: Comment[];
    addComment: string;
    setReplyId: string;
  instaModal: boolean;
  instaModalIndex: number;
  allStoryHeader: Array<StoryUploaders>;
  userStory: Array<StoryUploaders>;
  goToSlide: string;
  storyMuted: boolean;
  storyPaused: boolean;
  currentStory: number;
  currentSlideIndex: number;
  showLeftScrollBtn: boolean;
  showRightScrollBtn: boolean;
    isSellerPostData : boolean;
    isShareModal : boolean;
    shareUrl: string;
    sharePostId : string;
    snackMessage: string;
    isSnackBarOpen: boolean;
    snackSeverity: 'info' | 'error' | 'success' | 'warning';
    profileImage: string;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerFeedController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSellerPostDataApiCallId : string = "";
    sellerPostContainerRef: React.RefObject<HTMLDivElement> = createRef();
    postToggleLikeUnlikeApiCallID : string = "";
    postCommentOnCommentApiCallID: string = "";
    getPostCommentApiCallID: string = "";
    postCommentApiCallID: string = "";
    likeCommentApiCallID: string = "";
    likeReplyCommentApiCallID: string = "";
    allUserStoriesCallId: string = "";
    viewStoryCallId: string = "";
    likeStoryCallId: string = "";
    swiperInstance: SwiperCore | null = null;
    storyMainContainerRef : React.RefObject<HTMLDivElement> = createRef();
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isAppLoader: false,
            feedPageNo: 1,
            buyerPostLoader: false,
            sellerPostData: [],
            isFeedLoader: false,
            hasMorePosts: true,
            islatestPost: false,
            isSideBar: true,
            showCommentBox: "",
            clickReply: [],
            setPostCommentId: "",
            listCommentData: [],
            addComment: "",
            instaModal: false,
            allStoryHeader: [],
            userStory : [],
            goToSlide: "",
            storyMuted: false,
            storyPaused: false,
            currentStory: 0,
            currentSlideIndex: 0,
            instaModalIndex: 0,
            showLeftScrollBtn: false,
            showRightScrollBtn: false,
            setReplyId: "",
            isSellerPostData: true,
            isShareModal: false,
            shareUrl: '',
            sharePostId: '',
            snackMessage: '',
            isSnackBarOpen: false,
            snackSeverity: 'success',
            profileImage: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJSON = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            if (responseJSON.status === 500) {
                this.showAlert("Error", "Internal Server Error!!");
                this.setState({ isAppLoader: false });
                return;
            }
            if (responseJSON && !responseJSON.errors) {
              if(this.getSellerPostDataApiCallId === apiRequestId){
                this.sellerPostDataSuccessCallBack(responseJSON);
              }
            }

            if (apiRequestId && responseJSON) {
                switch (apiRequestId) {
                    case this.getPostCommentApiCallID:
                        this.getListingDataSuccessCallback(responseJSON);
                        break;
                    case this.postCommentApiCallID:
                        this.getAddDataSuccessCallback(responseJSON);
                        break;
                    case this.postCommentOnCommentApiCallID:
                        this.postCommentOnCommentDataSuccessCallback(responseJSON);
                        break;
                    case this.likeCommentApiCallID:
                        this.likeCommentCallback(responseJSON);
                        break;
                    case this.likeReplyCommentApiCallID:
                        this.likeReplyCommentCallback(responseJSON);
                            break;
                    default:
                        break;
                }
            }
            this.handleMoreApiResponse(message);
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
      let profileImage = await getStorageData("profileImage")
      this.setState({ profileImage: profileImage })
        this.getSellerPostListingData();
        this.fetchAllUserStories();
        if (this.sellerPostContainerRef.current) {
            this.sellerPostContainerRef.current.addEventListener('scroll', this.handleScroll);
        }
    };

    handleMoreApiResponse = (message: Message) => {
        const apiRequestId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJSON = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        )  
        if (apiRequestId === this.likeStoryCallId) {
          this.likeStorySuccessCallBack(responseJSON)
        };

        if (apiRequestId === this.allUserStoriesCallId) {
          this.allUserStoriesSuccessCallBack(responseJSON)
        };
  
        if (apiRequestId === this.viewStoryCallId) {
          this.viewStorySuccessCallBack(responseJSON)
        };
    };

    handleScroll = () =>{
        if (this.sellerPostContainerRef.current) {
            const container = this.sellerPostContainerRef.current;
            if ( container.scrollTop + container.clientHeight >= container.scrollHeight -10  &&  !this.state.isAppLoader && this.state.hasMorePosts && this.state.isSellerPostData ) { 
                this.setState({isSellerPostData: false });
                this.getSellerPostListingData(true); 
            }
        }
    };

    getSellerPostListingData = async (isFromBottomPagination = false) => {
        if (isFromBottomPagination) {
            this.setState({ isFeedLoader: true });
        }

        this.getSellerPostDataApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getPostListingDataEndPoint + `?page=${this.state.feedPageNo}&per_page=5`,
            body: '',
            type: ''
        });
    };

    apiCall = async (apiRequestData: ApiPayload) => {
        const { contentType, method, endPoint, body, type } = apiRequestData;
        let token = await getStorageData("singupLogin");
        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body && type != 'formData' ?
            requestApiMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestApiMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
        return requestApiMessage.messageId;
    };

    sellerPostDataSuccessCallBack = (responseData: PostApiResponsePayload) => {
        if (responseData && responseData.data) {
            if(responseData.data.length === 0){
                this.setState({hasMorePosts: false});
            } else{
                this.setState((prevState)=> ({
                    buyerPostLoader: false,
                    islatestPost: false,
                    sellerPostData: [ ...prevState.sellerPostData, ...responseData.data ], 
                    feedPageNo: prevState.feedPageNo + 1,
                    isFeedLoader: false,
                    isSellerPostData: true,
                  }));
            }
          }
    };

    toggleSideBar = () => {
        this.setState({isSideBar: !this.state.isSideBar});
    };

    changeStory = (direction: "next" | "prev") => {
      let { currentStory, userStory, currentSlideIndex, allStoryHeader } = this.state;
  
      if (userStory.length === 0) return;
  
      let newIndex = currentStory;
  
      if (direction === 'next') {
        newIndex = currentStory + 1;
      } else if (direction === 'prev') {
        newIndex = currentStory - 1;
      }
      if (newIndex === userStory.length) {
        if (currentSlideIndex + 1 < allStoryHeader.length) {
          this.handleNext(currentSlideIndex, true);
        } else {
          allStoryHeader[currentSlideIndex].attributes.seen = true;
          this.handleClose();
        }
        newIndex = 0;
      } else if (newIndex < 0) {
        this.handlePrev(currentSlideIndex, true);
        newIndex = 0;
      }
  
      this.setState({ allStoryHeader, currentStory: newIndex });
    };

    handlePostLikeUnlike = (postId: string) => {
        const updatedPostData = [...this.state.sellerPostData];
        const postIndex = updatedPostData.findIndex(post => post.id === postId);

        if (postIndex !== -1) {
            const isLiked = updatedPostData[postIndex].attributes.is_liked;
            const likeCnt = updatedPostData[postIndex].attributes.like_count;
            if (isLiked === true) {
                updatedPostData[postIndex].attributes.is_liked = false;
                if (likeCnt > 0) {
                    updatedPostData[postIndex].attributes.like_count = likeCnt - 1;
                }
                this.setState({ sellerPostData: updatedPostData }, () => {
                    this.postApiCallFn(postId);
                });
            } else {
                updatedPostData[postIndex].attributes.is_liked = true;
                updatedPostData[postIndex].attributes.like_count = likeCnt + 1;
                this.setState({ sellerPostData: updatedPostData }, () => {
                    this.postApiCallFn(postId);
                });
            }
        }
    };

    postApiCallFn = async (postId: string) => {
        this.postToggleLikeUnlikeApiCallID = await this.apiCall({
            method: configJSON.PostAPiMethod,
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.postLikeUnlikeEndPoint,
            body: {
                "data": {
                    "attributes": {
                        "likeable_type": "BxBlockPosts::Post",
                        "likeable_id": Number(postId),
                    }
                }
            },
            type: ''
        })
    };

    handleToggleComment = (postId:string,commentbox:string) => {
        this.getPostCommentApiCall(postId);
        this.setState({ setPostCommentId: postId, showCommentBox : commentbox });
    }

    handleInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ addComment: event.target.value}) 
    }

    postCommentApiCall = async () => {
        this.postCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.postCommentEndPoint,
            method: configJSON.PostAPiMethod,
            body: {
                "comment": {
                    "comment": this.state.addComment,
                    "post_id": this.state.setPostCommentId
                }
            },
            type: ''
        })
    };

    getPostCommentApiCall = async (postId:string) => {
        this.getPostCommentApiCallID = await this.apiCall({
            endPoint: `${configJSON.getPostCommentListEndPoint}?post_id=${postId}`,
            method: configJSON.validationApiMethodType,
            type: '',
            contentType: configJSON.validationApiContentType,
        })
    };

    postCommentOnCommentApiCall = async () => {
        this.postCommentOnCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            endPoint: configJSON.postCommentOnCommentEndPoint,
            body: {
                "comment": {
                    "comment": this.state.addComment,
                    "comment_id": this.state.setReplyId,
                    "post_id": this.state.setPostCommentId,
                }
            },
            type: ''
        })
    };

    getAddDataSuccessCallback = (responseJson: CommentsResponse) => {
        if (responseJson && responseJson.data) {
            this.getPostCommentApiCall(this.state.setPostCommentId);
            this.setState({ addComment: "" });
          }
    };

    getListingDataSuccessCallback = (responseJson: CommentsResponse) => {
        if (responseJson && responseJson.data) {
            this.setState({ listCommentData: responseJson.data })
          }
    };

    postCommentOnCommentDataSuccessCallback = (responseJson:SubPostCommentResponse) => {
        if (responseJson && responseJson.data) {
            this.getPostCommentApiCall(this.state.setPostCommentId);
            this.setState({ addComment: "", setReplyId : "" });
        }
    }

    viewDetilsExpandable = (viewDetilsExpandableId: number) => {
        const viewDetailsExpandSet = new Set(this.state.clickReply);
        if (!viewDetailsExpandSet.has(viewDetilsExpandableId)) {
          viewDetailsExpandSet.add(viewDetilsExpandableId);
        } else {
          viewDetailsExpandSet.delete(viewDetilsExpandableId);
        }
        this.setState({ clickReply: Array.from(viewDetailsExpandSet) });
    }

    clickOnReplyForGetId = (idReply:string) => {
      if (this.state.setReplyId === idReply) {
        this.setState({ setReplyId: "" });
      } else {
        this.setState({ setReplyId: idReply });
      }
    }

    likeCommentApiCall = async (id:string) => {
        this.likeCommentApiCallID = await this.apiCall({
            endPoint: configJSON.likeCommentEndPoint,
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            body: {
                  "data": {
                    "attributes": {
                        "likeable_type": "BxBlockComments::Comment",
                      "likeable_id": id, //enter comment id
                    }
                  }
                },
            type: ''
        })
    };

    likeReplyCommentApiCall = async (id:string) => {
        this.likeReplyCommentApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.likeCommentEndPoint,
            method: configJSON.PostAPiMethod,
            body: {
                  "data": {
                    "attributes": {
                        "likeable_type": "BxBlockComments::SubPostComment",
                      "likeable_id": id, //enter comment id
                    }
                  }
                },
            type: ''
        })
    };

    likeReplyCommentCallback = (responseJson:LikeResponse) => {
        if (responseJson) {
            this.getPostCommentApiCall(this.state.setPostCommentId);
        }
    }

    likeCommentCallback = (responseJson:LikeResponse) => {
        if (responseJson) {
            this.getPostCommentApiCall(this.state.setPostCommentId);
        }
    }

  handleClickOpen = (index: number) => {
    this.viewStory(index);
    this.setState({ instaModalIndex: index, currentSlideIndex: index })
  };

  viewStory = async (index: number) => {
    this.viewStoryCallId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        endPoint: configJSON.viewStoryEndPoint.replace("{userId}", this.state.allStoryHeader[index].attributes.creator_details.id),
        type: "",
        method: configJSON.validationApiMethodType
      }
    );
  };

  handleClose = () => { this.setState({ instaModal: false })};

  handleNext = (userIndex: number,
    direct?: boolean,
    event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    let { allStoryHeader } = this.state;
    if (this.state.currentStory + 1 < this.state.userStory.length && !direct) {
      this.changeStory("next");
    } else {
      if (this.swiperInstance) {
        allStoryHeader[this.state.currentSlideIndex].attributes.seen = true;
        this.setState({ allStoryHeader },
          () => {
            this.viewStory(userIndex + 1);
            this.setState({ goToSlide: "next", currentSlideIndex: userIndex + 1 });
          })
      }
    }
  };

  handlePrevNext = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, userIndex: number) => {
    const element = event.currentTarget;
    const parent = element.parentElement
    const classNames = parent?.classList;
    if (classNames?.contains('swiper-slide-next')) {
      this.handleNext(userIndex - 1, true, event);
    } else if (classNames?.contains('swiper-slide-prev')) {
      this.handlePrev(userIndex + 1, true, event);
    }
  };

  setSwiperRef = (swiper: SwiperCore) => {this.swiperInstance = swiper;};

  toggleMute = () => { this.setState({ storyMuted: !this.state.storyMuted });};

  calculateHours = (createdAt: string) => {
    const currentTime = Date.now();
    const createdTime = new Date(createdAt).getTime();
    const timeDifference = currentTime - createdTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    if (hours > 0) { return `${hours}h`; }
    else if (minutes > 0) { return `${minutes}m`; }
    else { return `${seconds}s`; }
  };

  likeStory = async (storyId: number) => {
    this.likeStoryCallId = await this.apiCall(
      {
        method: configJSON.PostAPiMethod,
        contentType: configJSON.validationApiContentType,
        body: {
          "data": {
            "attributes": {
              "likeable_type": "BxBlockPosts::Story",
              "likeable_id": storyId
            }
          }
        },
        endPoint: configJSON.postLikeUnlikeEndPoint,
        type: ""
      }
    )
  };

  showNextButton = (userIndex: number) => {
    const { allStoryHeader, userStory, currentStory } = this.state;
    if ((userIndex + 1 === allStoryHeader.length
      &&
      currentStory + 1 < userStory.length)
      ||
      userIndex + 1 < allStoryHeader.length) {
      return true;
    }
    return false
  };

  fetchAllUserStories = async () => {
    this.allUserStoriesCallId = await this.apiCall(
      {
        endPoint: configJSON.getAllStoriesEndPoint,
        method: configJSON.validationApiMethodType,
        type: "",
        contentType: configJSON.validationApiContentType
      }
    );
  };

  likeStorySuccessCallBack = (response: LikeResponse) => {
    let { userStory } = this.state;
    const olderStory = userStory;
    if (response.message) {
      userStory[this.state.currentStory].attributes.liked = !userStory[this.state.currentStory].attributes.liked;
    }
    if (response.data) {
      olderStory.forEach((item, index) => {
        if (item.attributes.id === response.data.attributes.likeable_id) {
          userStory[index].attributes.liked = !item.attributes.liked;
        }
      })
    };
    this.setState({ userStory });
  };

  viewStorySuccessCallBack = (response: AllStoryResponse) => {
    let { goToSlide } = this.state;
    this.setState(
      {
        instaModal: true,
        userStory: response.data,
        currentStory: 0
      },
      () => {
        if (goToSlide === "prev") { this.swiperInstance?.slidePrev(); }
        else if (goToSlide === "next") { this.swiperInstance?.slideNext(); }
        this.setState({ goToSlide: "" });
      });
  };


  showPreviousButton = (userIndex: number) => {
    const { currentStory } = this.state;
    if ((userIndex === 0 && currentStory !== 0) || userIndex > 0) {
      return true;
    }
    return false
  };

  allUserStoriesSuccessCallBack = (response: AllStoryResponse) => {
    if (response.data) {this.setState({allStoryHeader: response.data},()=>{
      if (this.state.allStoryHeader.length){
        this.setState({showRightScrollBtn: true});
        this.updateScrollButtons();
        this.storyMainContainerRef.current?.addEventListener('scroll', this.handleStoryBoxScroll);
      }
    })}
  };

  handlePrev = (userIndex: number, direct?: boolean, event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    if (this.state.currentStory - 1 >= 0 && !direct) {this.changeStory("prev");}
    else {
      if (this.swiperInstance) {
        this.viewStory(userIndex - 1);
        this.setState({ goToSlide: "prev", currentSlideIndex: userIndex - 1 });
      }
    }
  };

  handleCreatorFollow = async (id: number) => {
    console.log(id);
    
  };

  handleStoryBoxScroll = () => {
    this.updateScrollButtons();
  };

  updateScrollButtons = () => {
    const container = this.storyMainContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;

      const atStart = scrollLeft === 0;
      const atEnd = scrollLeft + clientWidth >= scrollWidth;

      this.setState({
        showLeftScrollBtn: !atStart,
        showRightScrollBtn: !atEnd,
      });
    };

  };

  scrollStoryBoxLeft = () => {
    this.storyMainContainerRef.current?.scrollBy({ left: -200, behavior: 'smooth' });
  };

  scrollStoryBoxRight = () => {
    this.storyMainContainerRef.current?.scrollBy({ left: 200, behavior: 'smooth' });
  };

        
    toggleShareModal = () => {
        this.setState({ isShareModal: !this.state.isShareModal });
    };

    handlePostShareToggle = (postId: string) => {
        this.setState({ isShareModal: true, sharePostId: postId }, () => {
            this.setState({ shareUrl: `${postBaseUrl}/visit_post/${this.state.sharePostId}` })
        });
    };

    handleCopyShareLink = () => {
        const { shareUrl } = this.state;
        navigator.clipboard.writeText(shareUrl)
            .then(() => {
                this.setState({ snackMessage: 'Link Copied Succesfully!', isSnackBarOpen: true, snackSeverity: 'info' });
                setTimeout(() => {
                    this.setState({ snackMessage: '', isSnackBarOpen: false });
                }, 6000);
            })
    };

    handleSnackbarToggle = () => {
        this.setState({ isSnackBarOpen: !this.state.isSnackBarOpen })
    };

  handleKeyDown = (event: React.KeyboardEvent) => {
    if (this.state.addComment.length === 0) return;
    if (event.key === "Enter") {
      event.preventDefault();
      this.state.setReplyId === ""
        ? this.postCommentApiCall()
        : this.postCommentOnCommentApiCall();
    }
  };
    // Customizable Area End
}